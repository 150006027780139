import React from 'react';
import styled from 'styled-components';
import { Section, SubSection, SubSectionHeader } from '../../../shared/Section';
import { Centralized } from '../../../shared/layout';
import { Paragraph } from '../../../shared/Paragraph';
import { PageContent } from '../../../shared/Page';

const StyledOurVision = styled(Section)`
  padding-top: 2rem;
  padding-bottom: 0;
`;

const OurVision = () => (
  <StyledOurVision>
    <Centralized>
      <PageContent>
        <SubSection>
          <SubSectionHeader>Our vision</SubSectionHeader>
          <Paragraph>
            My ultimate goal would be for Hand Carry to be the fastest and most
            reliable shipping service available while still being the most
            competitive on price. I’d love to expand our services beyond
            intra-city to inter-city and eventually cover as much of Japan as
            possible. After we achieve that, expansion into suitable cities
            around the world would be our next goal. But Hand Carry is first
            and foremost a Japanese company providing services to Japan. I truly
            believe our entry into the market will actually be beneficial to all,
            including current logistic providers. If we can take the smaller,
            less profitable parcels, the current logistic providers they can
            refocus and increase their overall efficiency. Giving back to the
            community is my greatest pleasure. If we can give back as much as
            possible to the community, I have truly achieved something.
          </Paragraph>
        </SubSection>
      </PageContent>
    </Centralized>
  </StyledOurVision>
);

export { OurVision };