import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components/shared/layout';
import { Page } from '../components/shared/Page';
import { VisionBanner, OurVision, OurStory } from '../components/modules/VisionPage';
import { OurTeam } from '../components/modules/VisionPage/OurTeam';

const StyledVisionPage = styled(Layout)`
  text-align: center;
`;

const VisionPage = () => (
  <StyledVisionPage headerTransparent>
    <Page>
      <VisionBanner />
      <OurVision />
      <OurStory />
      <OurTeam />
    </Page>
  </StyledVisionPage>
);

export default VisionPage;
