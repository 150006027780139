import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle,
} from '../../shared/Banner';
import { PageImage } from '../../shared/PageImage';
import VisionBannerIconSource from './images/visionBannerIcon.png';
import { SCREEN_SIZE, STYLES } from '../../../style';

const StyledVisionBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
  ${SCREEN_SIZE.LG} {
    width: 27rem;
    margin: 0;
  }
`;

const VisionBanner = () => (
  <StyledVisionBanner color={STYLES.PAGE_VISION_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={VisionBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>HOW HAND CARRY BEGAN</BannerTitle>
        <BannerDescription>
          Learn about how Hand Carry the idea was born.
          Here you will find out and learn about the people
          who make it possible.
        </BannerDescription>
      </BannerContentItem>
    </BannerContent>
  </StyledVisionBanner>
);

export { VisionBanner };