import React from 'react';
import styled from 'styled-components';
import { Section, SubSection, SubSectionHeader } from '../../../shared/Section';
import { Centralized } from '../../../shared/layout';
import { Paragraph } from '../../../shared/Paragraph';
import { PageContent } from '../../../shared/Page';

const StyledOurStory = styled(Section)`
  padding-top: 1rem;
  padding-bottom: 0;
`;

const OurStory = () => (
  <StyledOurStory>
    <Centralized>
      <PageContent>
        <SubSection>
          <SubSectionHeader>Our story</SubSectionHeader>
          <Paragraph>
            Having always wanted to live in Japan, I looked for some problems to
            solve to give me a reason to relocate. I had to look within the industries
            I could apply our skill sets to for a greater chance of success. Since
            I was young I was always buying a lot of things online. Being a buyer
            and seller on eBay since 2004 I often wished for an easier and cheaper
            shipping option. Noticing Japan’s ecommerce boom & logistics labour shortage
            I revisited my earlier dream of creating a more efficient shipping alternative.
            With the rise of the gig economy it was obvious to me that decentralising
            logistics by leveraging Japan’s millions of commuters was a logical solution.
            In line with Japan’s proactive environmental approach and their legislation
            to push online retailers to comply with eco standards it just made sense.
          </Paragraph>
        </SubSection>
      </PageContent>
    </Centralized>
  </StyledOurStory>
);

export { OurStory };